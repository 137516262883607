import React from 'react'
import Carousel from 'react-bootstrap/Carousel';

import MiddleCon from './MiddleCon';
import "../Style/Slider.css";

import img1 from '../img/img4.jpg';
import img2 from '../img/img5.jpg';
import img3 from '../img/img6.jpg';
import img4 from '../img/img10.jpg';
import PrivacyPolicy from './PrivacyPolicy';



function Home() {
  return (
    <>


{/* <Carousel>
      <Carousel.Item>
        <img src={img2} className='img-fluid' alt=''/>
        
      </Carousel.Item>
      <Carousel.Item>
      <img src={img1}  className='img-fluid' alt=''/>
        
      </Carousel.Item>
      <Carousel.Item>
      <img src={img3}  className='img-fluid' alt=''/>
        
      </Carousel.Item>
      <Carousel.Item>
      <img src={img4}  className='img-fluid' alt=''/>
        
      </Carousel.Item>
    </Carousel> */}
    <div className="container-fluid">
    <img src={img1}  className='img-fluid' alt=''/>
    </div>

  <MiddleCon />


    
        </>
  )
}

export default Home