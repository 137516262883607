import React from 'react';
import "../Style/Lastpart.css";
import { FaLocationArrow } from "react-icons/fa";
import { FaRegCalendarAlt } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import product from '../img/women/images.jpg';
import product1 from '../img/women/Artboard 2.jpg';
import product2 from '../img/women/Image_Editor (2).png';
import product3 from '../img/women/Image_Editor (3).png';
import product4 from '../img/women/Image_Editor (4).png';
import product5 from '../img/women/Image_Editor (5).png';
import product6 from '../img/women/Image_Editor (7).png'
import product7 from '../img/women/Image_Editor (8).png';
import product8 from '../img/women/Image_Editor (9).png';
import product9 from '../img/women/Artboard 9.jpg';
import product10 from '../img/women/Artboard 10.jpg';
import product11 from '../img/cups_5.jpg';
import { Link } from "react-router-dom";


const LastPart = () => {
  return (
    <div>
         <div className="maincon3_container">
      <h3 style={{borderBottom:'1px solid black'}}>
        BEST SELLNG PRODUCTS!
      </h3>
      <div>
        <div className="slider">
          <div className="slide-track">
            <div className="slide">
              <img src={product} height={190} width={250} style={{borderRadius:"0.5rem"}} alt="image not load" />
              <h5><strong style={{color:'black', fontSize:'20px'}}>Office Bags</strong>
              </h5>
              <hr style={{ border: "1px solid red", width: "80%" }}></hr>
              <div>
                <div
                  style={{
                    color: "black",
          
                    marginTop: "0.5rem",
                    marginBottom: "0.5rem",
                  }}
                >
                   

                </div>
                <div>
               <Link to={'/Product_details/35'}>
                  <button class="button mt-4">
    <span class="button_lg">
        <span class="button_sl"></span>
        <span class="button_text">View details</span>
    </span>
</button>
               </Link>
                </div>
              </div>
            </div>
            <div className="slide">
              <img src={product2} height={190} width={250} style={{borderRadius:"0.5rem"}} alt="" />
              <h5>
              <strong style={{color:'black' ,fontSize:'20px'}}>Women's outfits</strong>
              

              </h5>
              <hr style={{ border: "0.1rem solid red", width: "80%" }}></hr>
              <div>
                <div
                  style={{
                    color: "black",
            
                    marginTop: "0.5rem",
                    marginBottom: "0.5rem",
                  }}
                >
                  

                </div>
                <div>
                <Link to={'/Product_details/37'}>
        <button class="button">
    <span class="button_lg">
        <span class="button_sl"></span>
        <span class="button_text">View details</span>
    </span>
</button>
               </Link>
                </div>
              </div>
            </div>
            <div className="slide">
              <img src={product3} height={190} width={250} style={{borderRadius:"0.5rem"}} alt="" />
              <h5><strong style={{color:'black' ,fontSize:'20px'}}>Women's outfits</strong>
              </h5>
              <hr style={{ border: "0.1rem solid red", width: "80%" }}></hr>
              <div>
                <div
                  style={{
                    color: "black",
                
                    marginTop: "0.5rem",
                    marginBottom: "0.5rem",
                  }}
                > </div>
                <div>
                <Link to={'/Product_details/38'}>
        <button class="button">
    <span class="button_lg">
        <span class="button_sl"></span>
        <span class="button_text">View details</span>
    </span>
</button>
               </Link>
                </div>
              </div>
            </div>
            <div className="slide">
              <img src={product4} height={190} width={250} style={{borderRadius:"0.5rem"}} alt="" />
              <h5><strong style={{color:'black' ,fontSize:'20px'}}>Women's outfits</strong>
              </h5>
              <hr style={{ border: "0.1rem solid red", width: "80%" }}></hr>
              <div>
                <div
                  style={{
                    color: "black",
         
       
                    marginTop: "0.5rem",
                    marginBottom: "0.5rem",
                  }}
                >



                  
              
                     

                </div>
                <div>
                <Link to={'/Product_details/39'}>
          <button class="button">
    <span class="button_lg">
        <span class="button_sl"></span>
        <span class="button_text">View details</span>
    </span>
</button>
               </Link>
                </div>
              </div>
            </div>
            <div className="slide">
              <img src={product5} height={190} width={250} style={{borderRadius:"0.5rem"}} alt="" />
              <h5>
                <strong style={{color:"black"}}>
                Women's outfits
                </strong>
             
              </h5>
              <hr style={{ border: "0.1rem solid red", width: "80%" }}></hr>
              <div>
                <div
                  style={{
                    color: "black",
            
                    marginTop: "0.5rem",
                    marginBottom: "0.5rem",
                  }}
                >
                 
                </div>
                <div>
                <Link to={'/Product_details/40'}>
        <button class="button">
    <span class="button_lg">
        <span class="button_sl"></span>
        <span class="button_text">View details</span>
    </span>
</button>
               </Link>
                </div>
              </div>
            </div>
            <div className="slide">
              <img src={product6} height={190} width={250} style={{borderRadius:"0.5rem"}} alt="" />
              <h5>
                <strong style={{color:'black' ,fontSize:'20px'}}>
                Women's outfits
                </strong>
              

              </h5>
              <hr style={{ border: "0.1rem solid red", width: "80%" }}></hr>
              <div>
                <div
                  style={{
                    color: "black",
            
                    marginTop: "0.5rem",
                    marginBottom: "0.5rem",
                  }}
                >
                </div>
                <div>
                <Link to={'/Product_details/41'}>
          <button class="button">
    <span class="button_lg">
        <span class="button_sl"></span>
        <span class="button_text">View details</span>
    </span>
</button>
               </Link>
                </div>
              </div>
            </div>
            <div className="slide">
              <img src={product7} height={190} width={250} style={{borderRadius:"0.5rem"}} alt="" />
              <h5>
                <strong style={{color:'black' ,fontSize:'20px'}}>
                Women's outfits
                </strong>
               

              </h5>
              <hr style={{ border: "0.1rem solid red", width: "80%" }}></hr>
              <div>
                <div
                  style={{
                    color: "black",
      
                    marginTop: "0.5rem",
                    marginBottom: "0.5rem",
                  }}
                >
                   
 
                </div>
                <div>
                <Link to={'/Product_details/42'}>
            <button class="button">
    <span class="button_lg">
        <span class="button_sl"></span>
        <span class="button_text">View details</span>
    </span>
</button>
               </Link>
                </div>
              </div>
            </div>
            <div className="slide">
              <img src={product8} height={190} width={250} style={{borderRadius:"0.5rem"}} alt="" />
              <h5> 
                <strong style={{color:'black' ,fontSize:'20px'}}>
                Women's outfits
                </strong>
              

              </h5>
              <hr style={{ border: "0.1rem solid red", width: "80%" }}></hr>
              <div>
                <div
                  style={{
                    color: "black",
           
         
                    marginTop: "0.5rem",
                    marginBottom: "0.5rem",
                  }}
                >
                   
                

                </div>
                <div>
                <Link to={'/Product_details/43  '}>
                  <button class="button">
    <span class="button_lg">
        <span class="button_sl"></span>
        <span class="button_text">View details</span>
    </span>
</button>
               </Link>
                </div>
              </div>
            </div>
            <div className="slide">
              <img src={product9} height={190} width={250} style={{borderRadius:"0.5rem"}} alt="" />
              <h5>
              <strong style={{color:'black' ,fontSize:'20px'}}>
              Women's outfits
                </strong>
             

              </h5>
              <hr style={{ border: "0.1rem solid red", width: "80%" }}></hr>
              <div>
                <div
                  style={{
                    color: "black",
                    fontSize: "1.2rem",
             
                    marginTop: "0.5rem",
                    marginBottom: "0.5rem",
                  }}
                >
                
                </div>
             
                <div>
                  <button class="button">
    <span class="button_lg">
        <span class="button_sl"></span>
        <span class="button_text">View details</span>
    </span>
</button>
                </div>
              </div>
            </div>
            <div className="slide">
              <img src={product10} height={190} width={250} style={{borderRadius:"0.5rem"}} alt="" />
              <h5>
              <strong style={{color:'black' ,fontSize:'20px'}}>
              
              Women's outfits
                </strong>
             
              </h5>
              <hr style={{ border: "0.1rem solid red", width: "80%" }}></hr>
              <div>
                <div
                  style={{
                    color: "black",
                    fontSize: "1.2rem",
                    fontWeight: "600",
                    marginTop: "0.5rem",
                    marginBottom: "0.5rem",
                  }}
                >
                  
                </div>
               
                <div>
          <button class="button">
    <span class="button_lg">
        <span class="button_sl"></span>
        <span class="button_text">View details</span>
    </span>
</button>
                </div>
              </div>
            </div>
           
          </div>
        </div>
      </div>
    </div>
      
    </div>
  )
}

export default LastPart
