import React from 'react'

function ReturnPolicy() {
  return (
    <div className='container py-4'>
        
  <h2>Refund and Returns Policy</h2>
  <p>At Detfix, we are committed to ensuring your satisfaction with every purchase you make on our platform.</p>
  <p>
    If there is an issue with your order, please contact our Customer Care team at +91 8517090809 or email us at detfixtech@gmail.com as soon as possible. Kindly provide detailed information regarding your issue, including your order number, product name, and model number. Indicate whether you prefer a refund or replacement.
  </p>
  <p>
    For bulk or customized orders, returns, replacements, or cancellations will be subject to management approval.
  </p>
  
  <h4>Return Policy</h4>
  <p>
    If your product arrives damaged or defective, you may return the item(s) shipped by Detfix for a refund or exchange under conditional circumstances*. Refunds for items returned within 7 days of the purchase date will be credited in full (excluding shipping charges) via the original payment method. Claims for damaged or missing items must be received within 2 business days of the package's receipt.
  </p>

  <h4>Return Exceptions:</h4>
  <p>
    If the following conditions are not met, Detfix reserves the right to charge a 20% restocking fee or refuse the return.
  </p>
  <ul>
    <li>Items must be returned within 7 days of the original purchase.</li>
    <li>Items and packaging must be received in new, unused condition.</li>
    <li>Items should not be altered in any way.</li>
    <li>The original manufacturer's packaging must be intact and securely repacked to avoid transit damage.</li>
    <li>All original items, manuals, and any included gifts must be returned with the product.</li>
    <li>Before returning the package, you must contact Detfix to receive a Return Authorization Number (RAN), which should be clearly marked on the shipping box.</li>
    <li>A copy of the original invoice or sales receipt must be included in the return package.</li>
  </ul>
  
  <h4>Shipping Charges:</h4>
  <ul>
    <li>If you exchange an item for the same value as the original purchase, no additional shipping charges will apply. Please return the package using a prepaid, insured, and traceable method to the address provided.</li>
    <li>Shipping and pickup charges for returns will be borne by the customer.</li>
    <li>If the product is damaged during transit, do not accept the package from the courier, and report it as "Package in Damaged Condition."</li>
  </ul>

  <h4>Refund and Exchange Processing Time:</h4>
  <ul>
    <li>Once we receive your return in good condition, we will process your refund within 5 working days via the original payment method.</li>
    <li>If you have opted for an exchange, please refer to our Shipping Policy for shipment timelines. You can find it at www.detfix.com/ShippingPolicy.</li>
  </ul>
  
  <h4>Cancellations Policy:</h4>
  <ul>
    <li>If you'd like to cancel your order, you may do so provided the order has not yet been shipped. A full refund will be issued to your account.</li>
    <li>If your order has already been shipped, it cannot be canceled. However, you may return the item for a refund. See our Return Policy for details.</li>
    <li>Detfix reserves the right to refuse or cancel orders due to pricing inaccuracies or stock unavailability.</li>
    <li>If an order is canceled after your card has been charged, the charged amount will be refunded to your card.</li>
  </ul>
  
    </div>
  )
}

export default ReturnPolicy