import React from 'react'

function PaymentPolicies() {
  return (
    <div className='container py-3'>
        <h3 style={{fontWeight:'500'}}>
    Payment Policy for Detfix: Your Trusted E-Commerce Platform
  </h3>
  <p>
    At Detfix, we are committed to providing a secure and seamless shopping experience for all our customers. This Payment Policy outlines the accepted payment methods, transaction processes, and security measures that ensure safe and reliable payments on our platform.
  </p>
  <h4>1. Accepted Payment Methods:</h4>
  <p>Detfix accepts the following payment methods for online purchases:</p>
  <ul>
    <li>
      Credit and Debit Cards: We accept major cards, including Visa, MasterCard, American Express, and Discover for secure transactions.
    </li>
    <li>
      PayPal: Complete your purchases using your PayPal account for added security.
    </li>
    <li>
      Apple Pay: Convenient and secure payments for iOS users through Apple Pay.
    </li>
    <li>Google Pay: Effortless payments for Android users with Google Pay.</li>
    <li>
      Net Banking: Pay directly from your bank account via net banking options.
    </li>
    <li>
      Cash on Delivery (COD): Available for select locations, allowing you to pay when your order is delivered.
    </li>
  </ul>
  <h4>2. Order Confirmation:</h4>
  <p>
    Once your order is placed, you will receive a confirmation email with all relevant details, including the order number, items purchased, and the total amount charged.
  </p>
  <h4>3. Pricing and Currency:</h4>
  <p>
    Prices displayed on Detfix are in the local currency based on your location and are inclusive of applicable taxes. Shipping fees, if applicable, will be calculated and displayed at checkout.
  </p>
  <h4>4. Secure Transactions:</h4>
  <p>
    Detfix uses advanced encryption technology to secure your payment details during transactions, ensuring your data is protected at all times.
  </p>
  <h4>5. Payment Processing:</h4>
  <p>
    Payments are processed immediately upon placing an order to ensure timely fulfillment. If any product is unavailable, you will be promptly refunded for that amount.
  </p>
  <h4>6. Refund and Cancellation Policy:</h4>
  <p>
    For detailed information on order cancellations and refunds, please refer to our Refund Policy. We are dedicated to handling these requests promptly and fairly.
  </p>
  <h4>7. Fraud Prevention:</h4>
  <p>
    To ensure the safety of our customers, Detfix implements strict fraud prevention measures. In certain cases, additional verification may be required to complete an order.
  </p>
  <h4>8. Billing Information:</h4>
  <p>
    Customers must provide accurate billing information during checkout. Inaccurate details can lead to delays in order processing.
  </p>
  <h4>9. Payment Issues:</h4>
  <p>
    If you experience any issues during payment or notice an incorrect charge, please contact our customer support at detfixtech@gmail.com for immediate assistance.
  </p>
  <h4>10. Changes to Payment Policy:</h4>
  <p>
    Detfix reserves the right to update this Payment Policy at any time. Any changes will take effect immediately upon being posted on our website, and we encourage customers to review it periodically.
  </p>
  <h4>11. Contact Information:</h4>
  <p>
    For any questions related to payments, feel free to contact us at:
  </p>
  <ul>
    <li>Email: detfixtech@gmail.com</li>
    <li>Phone: +91 8517090809</li>
  </ul>
  <p>
    Detfix is committed to ensuring a smooth and secure shopping experience. Our Payment Policy is designed to protect both you and your financial transactions, making your shopping experience safe and stress-free.
  </p>
    </div>
  )
}

export default PaymentPolicies